export function setLanguage(state, payload) {
  state.language = payload;
}

export function setWebp(state, payload) {
  state.webpSupported = payload;
}
export function setLoginToken(state, payload) {
  console.log("setLoginToken");
  state.loginToken = payload;
}
export function setUsername(state, payload) {
  state.username = payload;
}
export function setProfile(state, payload) {
  state.profile = payload;
}

export function setShowStickyHeader(state, payload) {
  state.showStickyHeader = payload;
}
export function logOut(state) {
  state.loginToken = null;
  state.username = null;
  state.profile = null;
}
