<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  beforeMount() {
    (async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.saveWebp(false);
        return;
      }

      // Base64 representation of a white point image
      const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then(r => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.saveWebp(await createImageBitmap(blob).then(() => true, () => false));
    })();
  },
  computed: {
    ...mapGetters('website',
        {
          webpSupported: 'getWebpSupported',
        },
    ),
// End created
  },
  methods: {
    ...mapActions('website',
        {
          saveWebp: 'saveWebp',
        },
    ),

  }
}
</script>
