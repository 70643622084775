import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";

Vue.use(VueRouter);

const defaultMeta = [
    {
        name: 'description',
        content: 'Os melhores seguros para freelancers, profissionais independentes, micro e pequenas empresas. Em minutos, sem complicações e com grandes poupanças.'
    },
    {
        property: 'keywords',
        content: 'seguros para empresas,melhores seguros para empresas,seguros para freelancers,seguros pme,seguros pequenas empresas,seguro de acidentes de trabalho,seguro de responsabilidade civil,seguro multirriscos,seguro automóvel,seguro de saúde,seguro de vida,seguro de acidentes pessoais'
    },
    window.location.hostname.startsWith('dev') ?         {
        property: 'robots',
        content: 'noindex'
    } : [],
];
/*
const defaultMetaNoKeywords = [
    window.location.hostname.startsWith('dev') ?         {
        property: 'robots',
        content: 'noindex'
    } : [],
];*/

const defaultMetaLegais = [
    {
        name: 'description',
        content: 'Os melhores seguros para freelancers, profissionais independentes, micro e pequenas empresas. Em minutos, sem complicações e com grandes poupanças.'
    },
    {
        property: 'keywords',
        content: 'seguros para empresas,melhores seguros para empresas,seguros para freelancers,seguros pme,seguros pequenas empresas,seguro de acidentes de trabalho,seguro de responsabilidade civil,seguro multirriscos,seguro automóvel,seguro de saúde,seguro de vida,seguro de acidentes pessoais'
    },
    {
        property: 'robots',
        content: 'noindex'
    },
];

const acidentesMeta = [
    {
        name: 'description',
        content: 'Seguro de Acidentes de Trabalho para freelancers, profissionais independentes, micro e pequenas empresas. Preço e compra online e imediata.'
    },
    {
        property: 'keywords',
        content: 'seguro de acidentes de trabalho,seguro AT,acidentes de trabalho,seguro para freelancer,seguro para profissionais independentes,melhor seguro de acidentes de trabalho,melhor seguro AT'
    },
    window.location.hostname.startsWith('dev') ?         {
        property: 'robots',
        content: 'noindex'
    } : [],

];

const Site = () => import('./views/Site')
const WorkInsurancePage = () => import('./views/WorkInsurancePage')
const ServiceCounterPage = () => import('./views/ServiceCounter')
const PoliticaPrivacidade = () => import('./views/PoliticaPrivacidade')
const PoliticaCookies = () => import('./views/PoliticaCookies')
const PoliticaTratamento = () => import('./views/PoliticaTratamento')
const GestaoReclamacoes = () => import('./views/GestaoReclamacoes')
/*const Wiki = () => import('./views/Wiki')
const Blog = () => import('./views/Blog')
const Post = () => import('./views/Post')*/
const UnsubscribeBlog = () => import('./views/UnsubscribeBlog')
const ChangePassword = () => import('./views/ChangePassword')
const Admin = () => import('./views/admin/Admin')
const AdminUsers = () => import('./views/admin/AdminUsers')
const AdminWalletUsers = () => import('./views/admin/AdminWalletUsers')
const AdminWalletNifs = () => import('./views/admin/AdminWalletNifs')
const AdminWalletPotentialNifs = () => import('./views/admin/AdminWalletPotentialNifs')

const AdminMarketplaceTransactions = () => import('./views/admin/AdminMarketplaceTransactions')
const AdminB2BWebWallet = () => import('./views/admin/AdminB2BWebWallet')
const AdminWalletItems = () => import('./views/admin/AdminWalletItems')
const AdminWalletCreateUser = () => import('./views/admin/AdminWalletCreateUser')
const AdminCreateUser = () => import('./views/admin/AdminCreateUser')
const AdminEditOwnUser = () => import('./views/admin/AdminEditOwnUser')
const AdminEditUser = () => import('./views/admin/AdminEditUser')
const AdminWalletViewUser = () => import('./views/admin/AdminWalletViewUser')
const AdminWalletViewUserWallet = () => import('./views/admin/AdminWalletViewUserWallet')
const AdminWalletCreateNif = () => import('./views/admin/AdminWalletCreateNif')
const AdminWalletEditNif = () => import('./views/admin/AdminWalletEditNif')
const Login = () => import('./views/admin/Login')
const ResetPassword = () => import('./views/admin/ResetPassword')

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/:lang/seguro-acidentes-trabalho",
            name: 'seguro-acidentes-trabalho',
            component: WorkInsurancePage,
            meta: {
                title: 'Seguro de Acidentes de Trabalho | Indie Seguros',
                metaTags: acidentesMeta,
                guest: true,
            },
        },
        {
            path: "/:lang/service-counter",
            name: 'service-counter',
            component: ServiceCounterPage,
            meta: {
                title: 'Insurance-as-a-Service | Indie Seguros',
                metaTags: acidentesMeta,
                guest: true,
            },
        },
        {
            path: "/:lang/politica-privacidade",
            name: 'politica-privacidade',
            component: PoliticaPrivacidade,
            meta: {
                title: 'Seguros para freelancers, micro e pequenas empresas | Indie Seguros',
                metaTags: defaultMetaLegais,
                guest: true,
            },
        },/*
        {
            path: "/:lang/blog",
            name: 'blog',
            component: Blog,
            meta: {
                title: 'Blog | Indie Seguros',
                metaTags: defaultMeta,
                guest: true,
            },
        },
        {
            path: "/:lang/blog/:slug",
            name: 'post',
            component: Post,
            meta: {
                metaTags: defaultMetaNoKeywords,
                guest: true,
            },
        },
        {
            path: "/:lang/wiki",
            name: 'wiki',
            component: Wiki,
            meta: {
                title: 'Wiki | Indie Seguros',
                metaTags: defaultMetaNoKeywords,
                guest: true,
            },
        },
        {
            path: "/:lang/wiki/:letter",
            name: 'wikiLetter',
            component: Wiki,
            meta: {
                metaTags: defaultMetaNoKeywords,
                guest: true,
            },
        },*/
        {
            path: "/:lang/unsubscribe/:email/:token",
            name: 'unsubscribe',
            component: UnsubscribeBlog,
            meta: {
                title: 'Unsubscribe | Indie Seguros',
                metaTags: defaultMeta,
                guest: true,
            },
        },
        {
            path: "/:lang/change-password/:email/:token",
            name: 'change-password',
            component: ChangePassword,
            meta: {
                title: 'Change Password | Indie Seguros',
                metaTags: defaultMeta,
                guest: true,
            },
        },
        {
            path: "/:lang/politica-tratamento",
            name: 'politica-tratamento',
            component: PoliticaTratamento,
            meta: {
                title: 'Politica de Tratamento | Indie Seguros',
                metaTags: defaultMetaLegais,
                guest: true,
            },
        },
        {
            path: "/:lang/gestao-reclamacoes",
            name: 'gestao-reclamacoes',
            component: GestaoReclamacoes,
            meta: {
                title: 'Gestão de Reclamações | Indie Seguros',
                metaTags: defaultMetaLegais,
                guest: true,
            },
        },
        {
            path: "/:lang/politica-cookies",
            name: 'politica-cookies',
            component: PoliticaCookies,
            meta: {
                title: 'Política de Cookies | Indie Seguros',
                metaTags: defaultMetaLegais,
                guest: true,
            },
        },
        {
            path: "/:lang/simulador",
            name: 'simulador',
            meta: {
                title: 'Seguros para freelancers, micro e pequenas empresas | Indie Seguros',
                metaTags: defaultMeta,
                guest: true,
            },

            beforeEnter(routeTo) {
                window.location.href = window.location.hostname.startsWith('dev') ?
                    ('https://dev.simulador.indie.pt/' + routeTo.params.lang) :
                    ('https://simulador.indie.pt/' + routeTo.params.lang);
            }
        },
        {
            path: "/:lang/administration",
            name: 'administration',
            component: Admin,
            meta: {
                title: 'Admin | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/users",
            name: 'administrationUsers',
            component: AdminUsers,
            meta: {
                title: 'Admin - Utilizadores | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/wallet/users",
            name: 'administrationWalletUsers',
            component: AdminWalletUsers,
            meta: {
                title: 'Wallet Admin - Utilizadores | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/wallet/nifs",
            name: 'administrationWalletNifs',
            component: AdminWalletNifs,
            meta: {
                title: 'Wallet Admin - NIFs Import List | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/wallet/nifs/potential",
            name: 'administrationWalletPotentialNifs',
            component: AdminWalletPotentialNifs,
            meta: {
                title: 'Wallet Admin - Lista NIFs Potenciais | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/b2bwebwallet",
            name: 'administrationB2BWebWallet',
            component: AdminB2BWebWallet,
            meta: {
                title: 'Wallet Admin - B2B Web Wallet | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/marketplace/transactions",
            name: 'administrationMarketplaceTransactions',
            component: AdminMarketplaceTransactions,
            meta: {
                title: 'Wallet Admin - Marketplace Transactions | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },/*
        {
            path: "/:lang/administration/wallet",
            name: 'administrationWallet',
            component: AdminWallet,
            meta: {
                title: 'Wallet Admin | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },*//*
        {
            path: "/:lang/administration/wallet/items/create",
            name: 'administrationWalletItems',
            component: AdminWalletItems,
            meta: {
                title: 'Wallet Admin | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },*/
        {
            path: "/:lang/administration/wallet/items/create/:user",
            name: 'administrationWalletItemsUser',
            component: AdminWalletItems,
            meta: {
                title: 'Wallet Admin - Criar Wallet | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/users/create",
            name: 'administrationAdminCreateUser',
            component: AdminCreateUser,
            meta: {
                title: 'Admin - Criar Utilizador Admin | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/wallet/users/create",
            name: 'administrationWalletCreateUser',
            component: AdminWalletCreateUser,
            meta: {
                title: 'Wallet Admin - Criar Utilizador | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/user/account",
            name: 'administrationEditOwnUser',
            component: AdminEditOwnUser,
            meta: {
                title: 'Admin - Editar Utilizador | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/user/:user",
            name: 'administrationEditUser',
            component: AdminEditUser,
            meta: {
                title: 'Admin - Editar Utilizador | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/wallet/user/:user",
            name: 'administrationWalletViewUser',
            component: AdminWalletViewUser,
            meta: {
                title: 'Wallet Admin - Ver Utilizador | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/wallet/user/:user/wallet/:wallet",
            name: 'administrationWalletViewUserWallet',
            component: AdminWalletViewUserWallet,
            meta: {
                title: 'Wallet Admin - Ver Wallet do Utilizador | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/wallet/nifs/create",
            name: 'administrationWalletCreateNif',
            component: AdminWalletCreateNif,
            meta: {
                title: 'Wallet Admin - Adicionar NIF | Indie Seguros',
                metaTags: defaultMetaLegais,
                requiresAuth: true,
            },
        },
        {
            path: "/:lang/administration/wallet/nifs/edit/:nif",
            name: 'administrationWalletEditNif',
            component: AdminWalletEditNif,
            meta: {
                title: 'Wallet Admin - Editar NIF | Indie Seguros',
                metaTags: defaultMetaLegais,
                guest: true,
            },
        },
        {
            path: "/:lang/administration/wallet/nif/create/:nif/:nome",
            name: 'administrationWalletCreateNifPotential',
            component: AdminWalletCreateNif,
            meta: {
                title: 'Wallet Admin - Criar NIF | Indie Seguros',
                metaTags: defaultMetaLegais,
                guest: true,
            },
        },
        {
            path: "/:lang/administration/login",
            name: 'login',
            component: Login,
            meta: {
                title: 'Admin - Login | Indie Seguros',
                metaTags: defaultMetaLegais,
                guest: true,
            },
        },
        {
            path: "/:lang/administration/recover/:token",
            name: 'ResetPassword',
            component: ResetPassword,
            meta: {
                title: 'Admin - Redefinir Password | Indie Seguros',
                metaTags: defaultMetaLegais,
                guest: true,
            },
        },
        {
            path: "/:lang",
            name: 'Site',
            component: Site,
            meta: {
                title: 'Seguros para freelancers, micro e pequenas empresas | Indie Seguros',
                metaTags: defaultMeta,
                guest: true
            },
        },
        {
            path: "/:lang/administration/*",
            redirect: { name: 'administration'}
        },
        {
            path: "/:lang/*",
            redirect: { name: 'Site'}
        },
        {
            path: "*",
            redirect: { name: 'Site'}
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (from && from.name && from.name.startsWith("wiki") && to && to.name.startsWith("wiki"))
                return { }
            else
                return { x: 0, y: 0 }
        }
    }
});

const languages = [/*'pt',*/ 'en'];
router.beforeEach( (to, from, next) => {
    const hasLang = to.params && to.params.lang;
    const hadLang = from.params && from.params.lang;

    //console.log(JSON.stringify(to.params) + " " + to.fullPath);
    // If the language hasn't changed since last route we're done
    if (!(hasLang && hadLang && from.params.lang.toLowerCase() === to.params.lang.toLowerCase())) {
        //console.log("REDIRECT: " + to.fullPath + " " + to.params.lang + " " + to.fullPath.endsWith("/" + to.params.lang))
        let redirect = !languages.includes(to.params.lang) && (to.fullPath.endsWith("/" + to.params.lang) || to.fullPath.endsWith("/" + to.params.lang + "/"));
        // Get the save language if it exists
        //if (to.params.lang)
        let lang = VueCookies.get("site_language") != null ? VueCookies.get("site_language") : navigator.language.split('-')[0];

        // Overwrite the language with the route if there is one
        if (hasLang && !redirect) {
            lang = to.params.lang.toLowerCase();
        }

        // Make sure the language is valid
        if (!languages.includes(lang)) {
            lang = 'en';
            //lang = 'pt';
        }

        // Set the website language based on the URL
        VueCookies.set("site_language", lang);
//call actions saveLanguage
        //console.log("Redirect to lang " + lang);
        // Redirect to a url with the language
        if (redirect || !hasLang) {
            return next(`/${lang}${to.fullPath}`);
        } else if (!languages.includes(to.params.lang)) {
            if (to.params.lang === 'blog') {
                return next(`/${lang}${to.fullPath}`);
            } else {
                let path = to.fullPath;
                path = path.replace("/" + to.params.lang + "/", "/" + lang + "/");
                return next(path);
            }
        }
    }
    document.documentElement.setAttribute('lang', to.params.lang)
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));


    if (to.matched.some((record) => record.meta.guest)) {
        console.log("Is guest:" + to.name);

        if (to.name === 'login') {
            if (sessionStorage.getItem("loginToken")) {
                next("/" + to.params.lang + "/administration");
                return;
            }
        }
        next();
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log("Is auth");
        if (sessionStorage.getItem("loginToken")) {
            console.log("authenticated");
            next()
            return
        }
        next("/" + to.params.lang + "/administration/login");
    } else {
        next()
    }
    next();
});

export default router;
