import Vue from "vue";
import "./registerServiceWorker";
import router from "./router";
import VueI18n from 'vue-i18n'

import "./styles/app.scss";

import toastr from 'toastr';
import 'toastr/toastr.scss';
import { GraphQLClient } from 'graphql-request';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/v4-shims.min.css';

import store from '../store/store';
import Vue2TouchEvents from 'vue2-touch-events'
import VueWebpImage from 'vue-webp-image';

//import pt from './i18n/pt'
import en from './i18n/en'

toastr.options = {
    "closeButton": true,
    "debug": false,
    "progressBar": true,
    "preventDuplicates": false,
    "positionClass": "toast-top-right",
    "showDuration": "400",
    "hideDuration": "1000",
    "timeOut": "7000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

Vue.use(VueI18n);
Vue.use(VueWebpImage);
Vue.use(Vue2TouchEvents)

const messages = {
    en: en,
    //pt: pt,
};
export const i18n = new VueI18n({
    //locale: navigator.language.split('-')[0], // set locale
    locale: 'en',
    fallbackLocale: 'en',
    messages, // set locale messages
})

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.prototype.window = window;
Vue.config.productionTip = false;

const graphcmsClient = new GraphQLClient(
    'https://api-eu-central-1.graphcms.com/v2/cl0ju2w9n4hes01z2axc93skz/master'
);
graphcmsClient.setHeader("Authorization", "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NDc0MzM1NjUsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2NsMGp1Mnc5bjRoZXMwMXoyYXhjOTNza3ovbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMjFhNTMzMTUtZGE3MC00YTA1LWFkNWMtOWY3OTMzMTEyYjQ0IiwianRpIjoiY2wwdGplcWZsMTl2djAxeTE1eW1oNWZyayJ9.SQU9ZnRuoYViJyn-T-rD1QAnWez-NWTMz-7WJidGgp3m2mZaZ4aZsCVbxBYmEt_4EYdw7qh5RE2RyG_w8VKuDXQHrEUpKTbwk-LaPgJFgtnO1NhkqvCWqk3O_vBInFz2UtyUxblfmVzMvFnCBeZWNUq-sVzW9qTxM0XnJK_duwYSHpsTEK5wlit3DiJn66OEW5pcc6qzkZCMZ-YHBgqRFfu0gjR9dQN72Z9fdua96oaSdnnnO997O0VAVC7SwHYTUj1CaPZFkiYnUSttq_VtO-pP-r2Bqd70dh2DaqbfcEVUm5Gpd59ou_9VuauTSLIVXlPyrbhpJ75BJjqpo0hBea6l8MBQe-3kv_xCUHUEOUdiOmStArLuelFlg1cfwdNGy_vPzgBclISe5IA8lMFzMgU908k9yQfar_6G_PHvzhUGC4sEU4WtyfpEWwB9siZptWbQIolnL8AcV3Mkrx3AYX4UOomiMpIASkUHX-sKMbQRLakUDzbP85S9xNNvxogn4NLkQUTsoVU8F51KIroslAxz2KdMSwtW4Qex57mmuQAbgvZfEzKptJHeVGh8EIZ7x9l5cKN9xnzI5GFTOPJLCmlwqHia4NFgukOpVx_mXJwUEyQ8cG5E0iVdlhXtGk9W4hSqmY04nqxXlBCg21GENs1FeJvYikRS2NwotCpc0ko");

Vue.mixin({
    beforeCreate() {
        this.$graphcms = graphcmsClient;
    },
});

import App from "./App.vue";
if(document.getElementById("app")) {
    new Vue({
        i18n,
        store,
        router,
        render: h => h(App)
    }).$mount("#app");
}

import axios from 'axios';

axios.interceptors.response.use(function (response) {
        if (response.data.token) {
            store.dispatch('website/saveLoginToken', response.data.token);
            sessionStorage.setItem("loginToken", response.data.token);
        }
        return response;
    },
    function (error) {
        if (error) {
            if (error.response.status === 401) {
                store.dispatch('website/logOut');
                sessionStorage.removeItem("loginToken");
                router.push({name: 'login'});
            }
        }
        return Promise.reject(error);
    })
